<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.UY.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="nombres"
            :label="$t('forms_flujo.form1_persona.SP.nombre')"
            :error="errors.get('nombres')"
          >
            <el-input
              ref="nombres"
              v-model="formulario1.nombres"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.nombre')"
              style="text-transform:uppercase;"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="apellidos"
            :label="$t('forms_flujo.form1_persona.SP.apellidos')"
            :error="errors.get('apellido_paterno')"
          >
            <el-input
              ref="apellidos"
              v-model="formulario1.apellidos"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.apellidos')"
              style="text-transform:uppercase;"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <pz-identity-document
            v-model="formulario1.identity"
            prop="identity"
            type="person"
            :type-label="$t('forms_flujo.form1_persona.UY.tipo_doc')"
            :number-label="$t('forms_flujo.form1_persona.UY.identity_doc')"
          />
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="fecha_nacimiento"
            :label="$t('forms_flujo.form1_persona.UY.fecha_nacimiento')"
            :error="errors.get('fecha_nacimiento')"
          >
            <el-date-picker
              ref="fecha_nacimiento"
              v-model="formulario1.fecha_nacimiento"
              type="date"
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              :picker-options="pickerOptions1"
              :placeholder="$t('forms_flujo.form1_persona.UY.fecha_nacimiento')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="telefono"
            :label="$t('forms_flujo.form1_persona.UY.telefono')"
            :error="errors.get('telefono')"
          >
            <el-input
              ref="telefono"
              v-model="formulario1.telefono"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.UY.telefono')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="cuota_maxima"
            :label="$t('forms_flujo.form1_persona.UY.cuota_maxima')"
            :error="errors.get('cuota_maxima')"
          >
            <el-input
              ref="cuota_maxima"
              v-model.number="formulario1.cuota_maxima"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.UY.cuota_maxima')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="destino_prestamo"
            :label="$t('forms_flujo.form1_persona.UY.destino_prestamo')"
            :error="errors.get('destino_prestamo')"
          >
            <el-input
              ref="destino_prestamo"
              v-model="formulario1.destino_prestamo"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.UY.destino_prestamo')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <pz-check-form
            v-model="formulario1"
            clearing
          />
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>

import {required, type, EspacioBlanco} from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css'
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import PzCheckForm from "@/components/Flujo/Formularios/Formularios1/CheckFields/ChecksForm";

export default {
    name: 'PzPersonForm1Prestama',
    components: {  PzFormFlujo, PzIdentityDocument, PzCheckForm},
    mixins: [PzBaseForm1],
    data() {


        return {
            activeClearing :true,
            dialogVisible: false,
            reference: 'formulario1',
            marital_status: [],
            sucursales: [],
            formulario1: {
                nombres: '',
                apellidos: '',
                telefono: '',
                fecha_nacimiento: '',
                identity: {document: 1},
                consulta_clearing:0,
                consulta_bcu:0,
                cuota_maxima: '',
                destino_prestamo:'',
                info_extra: {
                    clearing: null,
                },
                clearing: null,

            },
            view_sucursal : false,
            rules_formulario1: {
                'identity.document': [required('')],
                'identity.number': [required(''),EspacioBlanco()],
                nombres: [required(''),EspacioBlanco()],
                apellidos: [required(''),EspacioBlanco()],
                telefono: [required('')],
                fecha_nacimiento: [required('')],
                type_prestamo: [required('')],
                cuota_maxima: [required(''),
                    type({field: '', type: 'number'}),],
                destino_prestamo: [required('')],
            },
            pickerOptions1: {
                disabledDate(time) {
                    return Date.now() < time.getTime();
                },
            },
        };
    },
    created: function () {

    },
    methods: {
    },


}
</script>

